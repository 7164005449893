import { graphql, useStaticQuery } from 'gatsby'

/**
 * Custom hook to fetch partners data from Contentful
 * This uses Gatsby's useStaticQuery to fetch the data at build time
 *
 * @returns {Object} An object containing the partners block title and partners array
 */
export function usePartnersData() {
  const data = useStaticQuery(graphql`
    query PartnersQuery {
      allContentfulBlockPartners {
        edges {
          node {
            title
            partners {
              name
              type
              link
              featured
              displayInDropdown
              order
              page {
                slug
              }
            }
          }
        }
      }
    }
  `)

  const partnersBlock = data.allContentfulBlockPartners.edges[0]?.node

  return {
    title: partnersBlock?.title,
    partners: partnersBlock?.partners,
  }
}
