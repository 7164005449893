import { useMemo, useState } from 'react'

import useGlobalStartPage from '../../hooks/globalContent/useGlobalStartPage'
import useMicroCopy from '../../hooks/globalContent/useMicroCopy'
import usePageLink from '../../hooks/routing/usePageLink'
import useOnScroll from '../../hooks/useOnScroll'

export interface NavMenuLink {
  label: string
  to: string
  subpages?: NavMenuLinkSub[]
}

export interface NavMenuLinkSub {
  link: string
  title: string
  navigationTitle: string
}

export function useNavMenuLinks(): NavMenuLink[] {
  const globalStartPage = useGlobalStartPage()
  const homeLabel = useMicroCopy('text.home')

  const ourWorkLink = usePageLink(globalStartPage?.ourWorkPage?.slug)
  const aboutLink = usePageLink(globalStartPage?.aboutPage?.slug)
  const joinUsLink = usePageLink(globalStartPage?.joinUsPage?.slug)
  const servicesLink = usePageLink(globalStartPage?.servicesPage?.slug)
  const blogListingLink = usePageLink(globalStartPage?.blogListingPage?.slug)

  const pagesLinks =
    globalStartPage?.pages &&
    globalStartPage?.pages
      .filter((page) => page.visibleInMenu)
      .map((page) => {
        return {
          link: usePageLink(page.slug),
          title: page.title,
          navigationTitle: page.navigationTitle,
        }
      })

  // Subpages for services page
  const servicesSubpages = globalStartPage?.servicesPage?.subpages?.map(
    (page) => {
      return {
        link: usePageLink(page.slug),
        title: page.title ?? '',
        navigationTitle: page.navigationTitle ?? '',
      }
    }
  )
  // Using the services main page for the first item in array.
  const includeServicesMainPage =
    globalStartPage?.servicesPage.includeAsFirstSubpage

  const servicesMainPage = {
    link: servicesLink,
    title: globalStartPage?.servicesPage?.title ?? '',
    navigationTitle:
      globalStartPage?.servicesPage?.subpageNavigationTitle ?? '',
  }

  const servicesPages = includeServicesMainPage
    ? [servicesMainPage].concat(servicesSubpages ?? [])
    : servicesSubpages

  return useMemo(() => {
    if (!globalStartPage) {
      return []
    }

    const navMenuLinks: NavMenuLink[] = []

    if (globalStartPage.servicesPage) {
      navMenuLinks.push({
        label:
          globalStartPage.servicesPage.navigationTitle ??
          globalStartPage.servicesPage.title,
        to: servicesLink,
        subpages: servicesPages,
      })
    }

    if (globalStartPage.ourWorkPage) {
      navMenuLinks.push({
        label:
          globalStartPage.ourWorkPage.navigationTitle ??
          globalStartPage.ourWorkPage.title,
        to: ourWorkLink,
      })
    }

    if (globalStartPage.blogListingPage) {
      navMenuLinks.push({
        label:
          globalStartPage.blogListingPage.navigationTitle ??
          globalStartPage.blogListingPage.title,
        to: blogListingLink,
      })
    }

    if (globalStartPage.joinUsPage) {
      navMenuLinks.push({
        label:
          globalStartPage.joinUsPage.navigationTitle ??
          globalStartPage.joinUsPage.title,
        to: joinUsLink,
      })
    }

    if (globalStartPage.aboutPage) {
      navMenuLinks.push({
        label:
          globalStartPage.aboutPage.navigationTitle ??
          globalStartPage.aboutPage.title,
        to: aboutLink,
      })
    }

    if (pagesLinks) {
      pagesLinks.map((pageLink) => {
        navMenuLinks.push({
          label: pageLink.navigationTitle ?? pageLink.title ?? '',
          to: pageLink.link,
        })
      })
    }

    return navMenuLinks
  }, [globalStartPage, homeLabel])
}

export function useScrollNavVisibility() {
  const [isVisible, setIsVisible] = useState(true)
  const [lastScrollTop, setLastScrollTop] = useState(0)
  const [hasScrolled, setHasScrolled] = useState(false)

  useOnScroll((scrollTop) => {
    const scrollThreshold = 100
    const movementThreshold = 10

    if (scrollTop > scrollThreshold) {
      setHasScrolled(true)

      if (scrollTop > lastScrollTop + movementThreshold) {
        setIsVisible(false)
      } else if (scrollTop < lastScrollTop - movementThreshold) {
        setIsVisible(true)
      }
    } else {
      setIsVisible(true)
      setHasScrolled(false)
    }

    setLastScrollTop(scrollTop)
  })

  return { isVisible, hasScrolled }
}
