import { graphql } from 'gatsby'
import React from 'react'

import { Partner } from '../../../@import-types/contentful/Partner.types'
import useRootLink from '../../../hooks/routing/useRootLink'
import { createPageLink } from '../../../utils/routingUtils'
import { InternalLink } from '../../_elements/InternalLink/InternalLink'
import LayoutContainer from '../../_layouts/LayoutContainer'
import Heading2 from '../../_typography/Heading2'

import styles from './partners-block.module.scss'

export const PartnersBlockTypename = 'ContentfulBlockPartners'

export interface PartnersBlockProps {
  __typename: typeof PartnersBlockTypename
  title: string
  partners?: Partner[]
  isInDropdown?: boolean
  selectedOnly?: boolean
}

export default function PartnersBlock({
  title,
  partners,
  isInDropdown = false,
  selectedOnly = false,
}: PartnersBlockProps) {
  const rootLink = useRootLink()

  if (!partners) {
    return null
  }

  if (isInDropdown) {
    const validPartners = partners.filter(
      (partner) => partner.name && partner.name.trim() !== ''
    )

    const eligiblePartners = validPartners.filter(
      (partner) => partner.displayInDropdown !== false
    )

    let displayPartners = eligiblePartners
    if (selectedOnly) {
      const navMenuPartners = eligiblePartners.filter(
        (partner) => partner.displayInDropdown === true
      )

      if (navMenuPartners.length > 0) {
        displayPartners = navMenuPartners
      } else {
        const featuredPartners = eligiblePartners.filter(
          (partner) => partner.featured
        )

        if (featuredPartners.length > 0) {
          displayPartners = featuredPartners
        }
      }
    }

    const limitedPartners = displayPartners.slice(0, 12)

    return (
      <div className={styles.dropdownColumns}>
        {limitedPartners.map((partner) => {
          const partnerPageLink = partner.page?.slug
            ? createPageLink(rootLink, partner.page.slug)
            : null

          return (
            <div key={partner.name} className={styles.dropdownItem}>
              {partnerPageLink ? (
                <InternalLink
                  to={partnerPageLink}
                  segmentdata={{
                    anchor_text: partner.name,
                    position: 'navigation',
                    url: partnerPageLink,
                  }}
                >
                  {partner.name}
                </InternalLink>
              ) : (
                partner.name
              )}
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <LayoutContainer withMargin>
      <Heading2>{title}</Heading2>

      <ul className={styles.list}>
        {partners.map((partner) => {
          const partnerPageLink = partner.page?.slug
            ? createPageLink(rootLink, partner.page.slug)
            : null

          const renderedPartnerName = (
            <div className={styles.itemName}>{partner.name}</div>
          )

          return (
            <li key={partner.name} className={styles.item}>
              {partnerPageLink && (
                <InternalLink
                  className={styles.itemLink}
                  to={partnerPageLink}
                  segmentdata={{
                    anchor_text: partner.name,
                    position: 'body',
                    url: partnerPageLink,
                  }}
                >
                  {renderedPartnerName}
                </InternalLink>
              )}

              {!partnerPageLink && renderedPartnerName}

              <div className={styles.itemType}>{partner.type}</div>
            </li>
          )
        })}
      </ul>
    </LayoutContainer>
  )
}

export const PartnersBlockFragment = graphql`
  fragment PartnersBlockFragment on Node {
    ... on ContentfulBlockPartners {
      title
      partners {
        name
        type
        link
        featured
        displayInDropdown
        order
        page {
          slug
        }
      }
    }
  }
`
